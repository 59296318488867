.carousel-wrap {
    position: relative;
    margin: 2em 0;
    padding: 0 5em;

    .a11y-slider-container {
        position:static;
    }
}

.carousel__item {
    min-height:250px;
    padding: 0.5em;
}

.carousel__media {
    height: 250px;
}

.carousel__img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.carousel__item-title,
.carousel__item-link {
    font-size: 2rem;
    color: $color-navy;
    font-weight: 600;
}

.carousel__item-category {
    color: $color-blue-light;
    margin-bottom: 0.5em;
}