.breadcrumbs__list {  
    @extend %listreset;
    display: flex;
    gap:.5rem;
    margin:1rem 0;
}

.breacrumbs__item { 
    display:inline-flex; 
    gap:.5rem;
    font-size:.85em; 

    &:after {
        content:'>'; 
        display:block;
    }
    
    &:last-child {
        &:after { display:none; }
    }
}