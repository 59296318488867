.header__nav {
    .navbar-toggle {
        margin:0;
    }
}

.main-nav-wrap {
    position:absolute;
    top:100%;
    left:0;
    display: none;
    width:100%;
    background:$color-white;

    &.show {
        display: block;
    }
}

.main-nav {
    position: relative;
    align-items: center;
    gap:32px;
    list-style: none;
    margin:0;
    padding: 0;
    background: $color-white;

    .btn {
        color: $color-white;
    }

    .dropdown-menu {
        position:static;
        flex-direction: column;
        gap:.6rem;
        padding:.5rem;
        border-radius: 0;
        background:$color-sand;

        &.show {
            display: flex;
        }
    }
}

.main-nav__container {
    display: none;
    z-index: 9;
}

.main-nav__callout {
    display: none;
}

.main-nav__item {
    position: relative;
    padding: 0.5em 0;
    border-bottom: 1px solid $border-color;

    &:last-child {
        border-bottom: 0;
    }

    &.open .main-nav__container {
        display: block;
    }
}

.main-nav__link,
.main-nav__link.dropdown-toggle,
.main-nav__item-link {
    font-size: 1.125rem;
    padding: 0 0.5em;
    color:#000;
}

.main-nav__sub-link {
    color:var(--bs-link-color-rgb);
}

.main-nav__title {
    margin: 0.5em 0;
}

.main-nav__action {
    margin-top: 1em;
}

.main-nav__image {
    margin-bottom: 1em;
}

.main-nav__sub {
    position:relative;
    flex-direction: column;
    gap: 9px;
    border:0;
    margin: 0;
    padding: 0.5em 0;
    list-style: none;
    background: $color-sand;

    .main-nav__item {
        font-size: 1rem;
        padding: 0 0.5em;
        border-bottom:0;
    }
}

.main-nav__item.show .main-nav__sub {
    display: flex;
}

.header__nav .navbar-toggle {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:4px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid rgba(0, 39, 78, 0.1);
    margin:0;
    padding: 2px;

    .icon-bar {
        background-color: $color-navy;
        height: 3px;
        display: block;
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        transition:background-color .3s ease-in-out;
    }

    &:hover,
    &:focus {
        background-color: $color-navy;

        .icon-bar {
            background-color: $color-white;
        }
    }
}

.dropdown-toggle {
    position: relative;
    @extend %buttonreset;

    &:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}

@media (min-width: $bp-l) {
    .main-nav-wrap {
        position: static;
        display: block !important;
        height: auto !important;
        margin: 0;
    }

    .header__nav .navbar-toggle {
        display: none;
    }

    .main-nav {
        display: flex;
        flex-flow: row wrap;

        .dropdown-menu {
            position:absolute;
            padding:1.5rem;
            background:$color-white;
            box-shadow: 0 3px 5px 0 rgba(0,0,0,.5);
        }
    }

    .main-nav__item.open .main-nav__container {
        display: flex;
        margin-top: 1em;
    }

    .main-nav__container {
        background: $white;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
        padding: 1em;
        position: absolute;
        top: 100%;
        left: 0;
        width: fit-content;
    }

    .main-nav__item {
        padding: 0;
        border-bottom: 0;

    }

    li.main__sub-item.main-nav__item--first:first-child {
        a {
            border-bottom: 2px solid $color-blue-dark;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .main-nav__item--first {

        li.main__sub-item:first-child a {
            border-bottom: 2px solid $color-blue-dark;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .main__sub {
        background: $white;
        flex: 1 60%;

        +.main-nav__callout {
            width: 40%;
        }
    }

    .main-nav__sub-link {
        white-space: nowrap;
    }

    .main-nav__callout {
        display: block;
        padding: 0 1em;
    }

    .main-nav > .main-nav__item:nth-last-child(-n+2) {
        .main-nav__sub {
            left:auto;
            right:0;
        }
    }
}

@media (min-width: 90em) {
    .main-nav__link,
    .main-nav__item-link {
        font-size: 1.3rem;
        padding: 0 1em;
    }
}