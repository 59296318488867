

@font-face {
    font-family: 'ProximaNova';
    src: url(/dist/fonts/ProximaNova-Regular.ttf);
}

@font-face {
    font-family: 'ProximaNovaCond';
    src: url(/dist/fonts/ProximaNovaCond-Regular.ttf);
}


@font-face {
    font-family: 'ProximaNovaSemiBold';
    src: url(/dist/fonts/ProximaNova-Semibold.ttf);
}

@font-face {
    font-family: 'ProximaNovaBold';
    src: url(/dist/fonts/ProximaNova-Bold.ttf);
}

@font-face {
    font-family: 'BerninaSans';
    src: url(/dist/fonts/Bernina-Sans-Compressed.otf);
}

@font-face {
    font-family: 'BerninaSansBold';
    src: url(/dist/fonts/Bernina-Sans-Compressed-Bold.otf);
}

.site-main .sf-content-block a:not(.btn) {
    font-family: $font-primary-bold;
}

a {
    text-decoration: underline;

    &:hover,
    &:focus-visible {
        text-decoration: none;
    }

    .site-header &,
    .card-icon &,
    &.carousel__item-link,
    &.btn {
        text-decoration: none;
    }

    .site-header &,
    &.carousel__item-link {
        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }
    }
}