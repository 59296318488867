.banner {
	border-bottom: 3px solid $red;
	position: relative;
	a {
	  display: inline-block;
	}
	h1, h2, h3, h4, h5, p {
	  color: $white;
	}
	h1 {
	  margin: 0;
	  padding: 0;
	  text-align: left;
	  @include responsive-headlines(4em);
	}
	ol.breadcrumb {
	  background-color: transparent;
	  margin: $space-xs 0;
	  padding: 0;
	  @include border-radius(0);
	  > li {
		font-family: $font-family-cond;
		font-size: $font-size-sm;
		font-weight: $fontWeight-semibold;
		letter-spacing: 0.03em;
		text-transform: uppercase;
	  }
	  > li a {
		color: rgba($white, 0.7);
	  }
	  > li + li:before {
		color: rgba($white, 0.5);
		content: "\f105";
		font-family: $font-family-icons;
		padding: 0 6px 0 4px;
	  }
	}
	p {
	  @include paragraph-big($font-size-xl);
	}
	.banner-click {
	  height: 100%;
	  left: 0;
	  position: absolute;
	  top: 0;
	  width: 100%;
	  z-index: 10;
	  &:hover {
		text-decoration: none;
	  }
	}
	.banner-gradient:after {
	  bottom: 0;
	  content: '';
	  height: 100%;
	  left: 0;
	  position: absolute;
	  width: 100%;
	  @include linear-gradient(rgba(#000, 0.2), rgba(#000, 0.5), 10%, 80%);
	  @media (min-width: $bp-m) {
		@include linear-gradient(rgba(#000, 0), rgba(#000, 0.5), 10%, 80%);
	  }
	}
	.banner-header {
	  padding: $space-md $space-sm;
	  position: relative;
	  @media (min-width: $bp-m) {
		bottom: 0;
		position: absolute;
		width: 100%;
	  }
	}
	&.banner-centered {
	  h1, h2, h3, h4, h5, p {
		text-align: center;
	  }
	}

	.media-topic {
		color: $color-white;
		font-family: $font-family-cond;
		font-size: 0.8125rem;
		font-weight: $fontWeight-semibold;
		margin: .6em 0 0;
		text-transform: uppercase;
	}
  }

.banner {
	&.hover-effect>a {
		display: block;
	}

	@media(min-width:48em) {
		height: 430px;
	}
}

.promo .promo-header img {
	border-bottom: 0;
}

.color-banner {
	position: relative;
	z-index: 1;
	margin-bottom: 30px;
	border-radius: 8px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	color: #fff;

	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 8px;
	}

	&.none {
		
		.title {}
		
		&:after {
			display: none;
		}		
	}

	&.navy {
		
		.title {
			background-color: #003c69;
		}

		&:after {
			background-color: #003c69;
		}		
	}

	&.grey {
		
		.title {		
			background: #7694aa;
		}
		
		&:after {
			background: #7694aa;
		}		
	}

	&.palepurple {
		
		.title {
			background: #8592af;
		}
		
		&:after {
			background: #8592af;
		}		
	}

	&.purple {
		
		.title {
			background: #334a7a;
		}
		
		&:after {
			background: #334a7a;
		}		
	}

	&.has-image {
		
		.title {}

		&:after {
			opacity: .9;
		}		
	}

	.content {
		position: relative;
		z-index: 2;
		padding: 45px 18px;
	}

	.title {
		margin-bottom: .4em;
		font-size: 2.24em;
		font-weight: 300;
		color: #fff;
	}

	.subtext {
		margin-bottom: 0;
		font-size: 1.125em;
		color: #fff;
	}

	@media(min-width:75em) {
		max-height: 430px;
		overflow: hidden;

		.title {
			font-size: 2.8em;
		}
	}
}

.card-banner {
	.banner {
		height: 220px !important;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}