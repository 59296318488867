label,
.form-label {
  display: block;
 text-transform: uppercase;
}

// required
.required-indicator {
  &:before {
    content: "*";
    color: $color-red;
  }
}

// Removes the clear button on search inputs
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

select {
  padding-right:20px;
  background:url('/dist/images/chevron-down.png') no-repeat right center;
}

.select-dropdown {
  .dropdown-toggle {
    display: inline-flex;
    align-items: center;
    border: var(--bs-border-width) solid #00274e;
    border-radius: 5px;
    padding: .375rem .75rem;
    background:$color-white;
    color: var(--bs-body-color);

    &:after {
      margin-left:auto;
    }
  }

  .dropdown-menu {
    width:100%;
  }
}