.list.list--vertical-tabs {
    .list__item-title {
      font-family:$font-heading;
      font-weight:$fontWeight-bold;

      &:not(:first-child) {
        margin-top:1rem;
        padding-top:1rem;
        border-top:1px solid #ccd4dc;
      }
    }
}

@media(min-width:$bp-mw){
  .list.list--vertical-tabs {
    .list__inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap:2rem;

      .list__item-body {
        grid-column:2;
        width:100%;
        position:static;
        order:0;
        height:100%;
        grid-row:1/ span 100;
      }
    }

    .list__item-title {
      font-size:1.5rem;
    }

    .list__item-icon {
      width:20px;
      height:20px;
      background:transparent;
      border:2px solid $color-navy;
    }

    .active {
      .list__item-icon {
        background:$color-navy;

        &:before,
        &:after {
          background:$color-white;
        }
      }
    }

    .list__item-content {
      background: #d6dff8;
    }
  }
}
