.list.list--expandable {

    .list__item + .list__item {
        margin-top:1rem;
        padding-top:1rem;
        border-top:1px solid $color-lightgray2;
    }

    .list__item-title {
        font-size: 1.75rem;
        font-weight:$fontWeight-normal;
    }

    .list__item-trigger {
        display: flex;
        align-items: center;
        gap:8px;
        border: 0;
        margin: 0;
        padding: 0;
        background: none;
        text-align: left;
    }

    .list__item-icon {
        background: #d6dff8;
        width: 2.5rem;
        height: 2.5rem;
        min-width: 20px;
        min-height: 20px;
        margin-right: 1rem;
        position: relative;
        border-radius: 50%;

        &:before,
        &:after {
          content: " ";
          background: $color-navy;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3D(-50%, -50%, 0);
          transition: all 0.2s ease-in-out;
        }

        &:before {
            width: 14px;
            height: 2px;
        }

        &:after {
            width: 2px;
            height: 14px;
        }
    }

    .list__item-content {
        height: auto;
        max-height: 0;
        visibility: hidden;
        overflow: hidden;
    }


    .active {
        .list__item-icon {
            &:before,
            &:after {
                
                transform: translate3D(-50%, -50%, 0) rotate(45deg);
            }
        }

        .list__item-content {
            padding: 1rem;
            height: 100%;
            max-height: 100%;
            visibility: visible;
            position: relative;
            z-index: 100;
        }
    }
}

@media(min-width:$bp-mw){
    .list.list--expandable {
        .list__item-icon {

            &:before,
            &:after {
                color:$color-navy;
            }
    
            &:before {
                width:10px;
            }
    
            &:after {
                height:10px;
            }
        }
    }
}