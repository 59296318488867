@forward './leadership';

.person {
    display: flex;
    margin-bottom: 0.5rem;
    padding: 1rem;
    background: $color-white;
    word-break: break-all;

    [class*="col-"] & {
        position: relative;
        height:100%;
    }

    &__title {
        margin:0;
        font-size: 1.25rem;

        small {
            color: $color-navy;
            font-size: 0.875rem;
        }
    }

    &__icon {
        background: #d6dff8;
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        min-width: 3rem;
        border-radius: 50%;

        > span {
            text-align: center;
            padding: 0;
            display: block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__details {
        address {
            margin-top: 1rem;
            font-size: 0.875rem;

            a {
                color: $color-navy;
                text-decoration: underline;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}