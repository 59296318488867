@import 'typography';
@import 'print';

.sf-content-block {
    img {
        max-width:100%;
    }
}

.dropdown-item:is(a) {
    text-decoration: none;

    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }
}