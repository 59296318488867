/* ================================================================
   MIXINS
   - Xrem
   - Clearfix   
   - Responsive Headlines
   ================================================================ */

   @mixin x-rem($property, $values) {
    // Create a couple of empty lists as output buffers.
    $base-font-size: 16px; // should be consistent with your html/body font-size
    $px-values: ();
    $rem-values: ();
  
    // Loop through the $values list
    @each $value in $values {
      // For each property value, if it's in rem or px, derive both rem and
      // px values for it and add those to the end of the appropriate buffer.
      // Ensure all pixel values are rounded to the nearest pixel.
      @if $value == 0 or $value == 0px {
        // 0 -- use it without a unit
        $px-values: join($px-values, 0);
        $rem-values: join($rem-values, 0);
      } @else if
        type-of($value) ==
        number and not
        unitless($value) and
        (unit($value) == px)
      {
        // px value given - calculate rem value from base-font-size
        $new-rem-value: calc($value / $base-font-size);
        $px-values: join($px-values, round($value));
        $rem-values: join($rem-values, #{$new-rem-value}rem);
      } @else if
        type-of($value) ==
        number and not
        unitless($value) and
        (unit($value) == "%")
      {
        // % value given - don't add px or rem
        $px-values: join($px-values, #{$value});
        $rem-values: join($rem-values, #{$value});
      } @else if $value == auto {
        // auto - don't add px or rem
        $px-values: join($px-values, auto);
        $rem-values: join($rem-values, auto);
      } @else {
        // unitless value - use those directly as rem and calculate the px-fallback
        $px-values: join($px-values, round($value * $base-font-size));
        $rem-values: join($rem-values, #{$value}rem);
      }
    }
  
    // output the converted rules
    #{$property}: $rem-values;
  }
  
  /*  CLEARFIX MIXIN
  
    - EXAMPLE:
  
     div {
       @include clearfix;
     }
    ================================================================ */
  
  @mixin clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  
  /* ================================================================
     PSUEDO MIXIN
  
     - EXAMPLE:
  
     div::after {
       @include pseudo;
       top: -1rem; left: -1rem;
     }
     ================================================================ */
  
  @mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
  }
  
  // Adujust text size for breakpoints
  @mixin responsive-headlines($font-size) {
    font-size: $font-size * 0.8;
    @media (min-width: $bp-m) {
      font-size: $font-size * 0.9;
    }
    @media (min-width: $bp-l-m) {
      font-size: $font-size;
    }
  }
  
  // Big paragraph text
  @mixin paragraph-big($font-size, $font-weight: null) {
    font-size: $font-size;
    font-weight: $font-weight;
    margin-bottom: $font-size * 1.4;
  }
  
  // Gradient
  @mixin linear-gradient($top-color, $bottom-color, $top-percent: 0%, $bottom-percent: 100%){
    background: $top-color; /* Old browsers */
    background: -moz-linear-gradient(top,  $top-color $top-percent, $bottom-color $bottom-percent); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop($top-percent,$top-color), color-stop($bottom-percent,$bottom-color)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top-color $top-percent,$bottom-color $bottom-percent); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top-color $top-percent,$bottom-color $bottom-percent); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top-color $top-percent,$bottom-color $bottom-percent); /* IE10+ */
    background: linear-gradient(to bottom,  $top-color $top-percent,$bottom-color $bottom-percent); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
  }
  
  // Generate the extra small columns
  @mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    float: left;
    width: percentage(($columns / $grid-columns));
    min-height: 1px;
    padding-left:  ($gutter / 2);
    padding-right: ($gutter / 2);
  }

/* ================================================================
   Placeholder Classes
================================================================ */

%buttonreset {
    border:0;
    margin:0;
    padding:0;
    background:none;
    cursor: pointer;
}

%listreset {
    margin: 0;
    padding: 0;
    list-style: none;
}

%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

%itemlist {
    @extend %listreset;
    display: flex;
    flex-direction: column;
   gap:30px;
}

%item__title {
    font-0siz

    a {
        text-decoration: none;

        &:hover, &:focus-visible {
            text-decoration: underline;
        }
    }
}