.event-detail__date {
    display:flex;
    flex-direction:column;
    gap:1rem;
    background:$color-primary;
    color:$color-white;
}

.event-detail__month,
.event-detail__year {
    @include x-rem(font-size, 40px);
}

.event-detail__month {
    padding-top:1rem;
}

.event-detail__year {
    padding-bottom:1rem;
}

.event-detail__day {
    @include x-rem(font-size, 60px);
    background:$color-white;
    color:$color-black;
}

.event-detail__time {
    display: flex;
    flex-wrap:wrap;
    gap:1rem;
}