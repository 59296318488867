/* ================================================================
   Variables
   ================================================================ */
  
  // Color Variables
  $color-black:#000;
  $color-white:#fff;
  $color-gray: #999999;
  $color-lightgray: #f0f0f0; 
  $color-lightgray2: #e6e6e6; 
  $color-blue-dark:  #003c69;
  $color-blue-dark2:  #23527c;
  $color-red: #cc0000;
  $color-blue: #007bff;
  $color-blue2: #337ab7;
  $color-blue3: #035489;
  $color-blue-light:#7694aa;
  $color-blue-lighter:#e5edf3;
  $color-blue-light-hover:#b9cfdf;
  $color-blue-light-alt:#D6DFF8;
  $color-blue-light-washed:#CCD4DC;
  $color-navy: #00274E;
  $color-navy2: #001F3E;
  $color-navy-hover: #000e1b;
  $color-navy-hover2: #212529;
  $color-sand: #F7F6F2;
  $color-tan: #F6F5F1;
  $color-mid-sand: #EEECE4;
  $color-dark-sand: #DDDAC8;
  $color-light-sand: #CCC7AD;
  $color-light-hover: #ececec;
  $color-yellow: #FFB400;
  $color-yellow2: #d99900;
  $color-gold: #FFD266;
  
  // m accents
  $color-blend-blue:#00224E;
  $color-blend-white:#E7E7E8;
  $color-blend-gold: #AFA279;

  $body-bg: $color-white;
  $color-base: $color-black;
  $color-primary: $color-navy;
  $color-secondary: $color-yellow;

  //Bootstrap Color Overrides
  $primary: $color-primary;
  $secondary: $color-secondary;
  
  $text-color: $color-navy;
  
  //Borders
  $border-color:             		#d8d8d8;
  
  
  // Typography
  $font-primary: "ProximaNova",Arial, sans-serif;
  $font-primary-bold: "ProximaNovaBold",Arial, sans-serif;
  $font-family-cond: "ProximaNovaCond", $font-primary;
  $font-secondary: "BerninaSansBold", Geneva, sans-serif;
  $font-heading: $font-secondary;
  $font-family-icons: "FontAwesome";
  
  $fontWeight-light: 300;
  $fontWeight-normal: 400;
  $fontWeight-medium: 500;
  $fontWeight-semibold: 600;
  $fontWeight-bold: 700;
  
  //Bootstrap typography overrides
  $body-color:$color-navy;
  $font-family-sans-serif: $font-primary;
  $headings-font-family: $font-heading;

  $h1-font-size:            		3.5rem;
  $h2-font-size:            		3rem;
  $h3-font-size:            		2.5rem;
  $h4-font-size:            		1.25rem;
  $h5-font-size:            		1.25rem;
  $h6-font-size:            		.75rem;
  $font-size-sm:            		0.875rem;
  $font-size-xl:            		1.25rem;
  $font-size-xs:            		.75rem;
  
  $line-height-base:        		1.4;
  $line-height-sm:          		1.2;

  $link-decoration: none;
  $link-hover-decoration: underline;

  // Bootstrap Button Overrides
  $btn-border-radius:          5px;
  $btn-padding-y:               .8em;
  $btn-padding-x:               1.2em;
  $btn-font-size:               0.875rem;
  $btn-font-weight:             $fontWeight-bold;
  .btn:hover,
  .btn:focus-visible { text-decoration: underline; }

  // Bootstrap Input Overrides
  $input-height: 34px;
  $input-border-color: $color-primary;
  $input-border-radius:5px;

  $form-label-font-size: .9375rem;
  $form-label-font-weight: $fontWeight-bold;
  $form-label-margin-bottom: .3rem;
  
  // Breakpoints
  // You can convert these at http://pxtoem.com
  $bp-s: 20em; // 320px
  $bp-s-max: 29.938em; // 479px
  $bp-sw: 30em; // 480px
  $bp-sw-max: 47.938em; // 767px
  $bp-m: 48em; // 768px
  $bp-m-max: 59.938em; // 959px
  $bp-mw: 60em; // 960px
  $bp-mw-max: 71.188em; // 1139px
  $bp-l: 72em; // 1140px
  $bp-l-m: 75em; // 1200px
  $bp-l-max: 81.188em; // 1299px
  $bp-xl: 81.25em; // 1300px

  // Bootstrap Grid Overrides
  $grid-columns:              12;
  $grid-gutter-width:         30px;
  $container-large-desktop:   (1170px + $grid-gutter-width);
  
  $nav-transition-speed: 0.4s;
  $transparent: transparent;
  $nav-barBackground: $transparent;
  $nav-mainLinkBackground: $color-white;
  $nav-subLinkBackground: $color-white;
  
  
  // Grid
  $grid-gutter: 20px;
  $grid-gutter-em: 1.053em;
  
  // Spacing
  $space-xl: $grid-gutter*2.5;
  $space-xs: $grid-gutter*.3;
  $space-lg: $grid-gutter*1.5; 
  $space-md: $grid-gutter; 
  $space-sm: $grid-gutter*.6; 
  
  // File Variables
  $images: "/dist/images";
  $fonts:"/dist/fonts/";