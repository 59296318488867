$sitemap-indent: 1.25rem !default;
$sitemap-heading-bg: $color-primary !default;
$sitemap-heading-bg-hover: darken($color-primary, 10%) !default;
$sitemap-tree-line: mix($color-secondary, $body-bg, 75%) !default;
$sitemap-tree-dot: 8px !default;

.sitemap__heading {
    display: block;
    width: 100%;
    border: 0;
    margin-bottom: 0.5rem;
    padding: 0.5rem $sitemap-indent;
    background: $sitemap-heading-bg;
    font-weight: $fontWeight-bold;
    text-align: left;
    text-decoration: none;
    color: $color-white;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
        background: $sitemap-heading-bg-hover;
    }
}

.sitemap {
    padding: 1.25rem 0;
}

.sitemap__item {
    display: block;
}

.sitemap__link {
    position: relative;
    display: block;
    padding: 4px 0;
    font-weight: bold;
    line-height: 1.2em;
}

.sitemap__list,
.sitemap__sub-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.sitemap>.sitemap__list>.sitemap__item {
    margin-bottom: 2rem;
}

.sitemap>.sitemap__list>.sitemap__item>.sitemap__link {
    @extend .sitemap__heading;
}

// Level 2
.sitemap .sitemap__sub-list {
    margin-left: $sitemap-indent;
}

// Level 3
.sitemap .sitemap__sub-list .sitemap__sub-list {
    margin-left: calc($sitemap-indent/2);
}

.sitemap .sitemap__sub-list .sitemap__sub-list .sitemap__link {
    font-size: 0.9rem;
}

// Level 4
.sitemap .sitemap__sub-list .sitemap__sub-list .sitemap__sub-list .sitemap__link {
    font-weight: normal;
    color: $color-base;
}

.sitemap>.sitemap__list>.sitemap__item>.sitemap__sub-list>.sitemap__item {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left:calc(-#{$sitemap-indent}/2);
        z-index: 2; //above the content, but below the dot
        height: 100%;
        width: 1px;
        background: $sitemap-tree-line;
    }

    &:first-child:before {
        top: 1em;
        height: 100%;
    }

    &:last-child:before {
        height: $sitemap-tree-dot;
    }

    &:first-child:last-child:before {
        height: 0;
    }

    // 2nd level dots
    &>.sitemap__link:after {
        content: '';
        position: absolute;
        top: 1em;
        left:calc(-#{$sitemap-indent} / 2 - #{$sitemap-tree-dot} / 2);
        z-index: 3;
        margin-top:calc(-#{$sitemap-tree-dot}/2 - 2px);
        height: $sitemap-tree-dot;
        width: $sitemap-tree-dot;
        border-radius: $sitemap-tree-dot;
        background: $sitemap-tree-line;
    }
}

@media(min-width:$bp-m) {
    .sitemap__list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }

    .sitemap__list>.sitemap__item {
        width: 50%;
    }
}

@media(min-width:$bp-m-max) {
    .sitemap__list>.sitemap__item {
        width: 33.3333%;
    }
}

@media(min-width:$bp-l) {
    .sitemap__list>.sitemap__item {
        width: 25%;
    }
}