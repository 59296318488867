.search-box {
    position:relative;
}

// autocomplete search results
.search-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 150;
}
.search-autocomplete__list {
    list-style: none;
    margin: 0;
    padding: 0;
    background: $color-primary;
    color: $color-white;
    border-radius: 5px;
}
.search-autocomplete__item {
    cursor: pointer;
}
.search-autocomplete__link {
    display: block;
    color: $color-white;
    text-decoration: none;
    padding: 5px 15px;
    &:hover,&:focus {
        text-decoration: underline;
    }
}