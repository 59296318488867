.a11y-slider-container {
    position: relative;
    direction: ltr;
  }
  
  .a11y-slider {
    position: relative;
    overflow: hidden;
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .a11y-slider::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .a11y-slider > * {
    scroll-snap-align: start;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  
  .a11y-slider-sr-only,
  .a11y-slider-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    text-align: center;
    font-family: inherit;
    font-size: 1em;
  }
  
  .a11y-slider-sr-only:focus {
    position: static;
    background: #000;
    color: #fff;
    width: 100%;
    height: auto;
    margin: 0;
    clip: auto;
    padding: 10px;
  }
  
  .a11y-slider-hide {
    display: none;
  }
  
  .a11y-slider-scrolling {
    cursor: -webkit-grabbing;
    cursor: grabbing;
    -webkit-scroll-snap-type: none;
        -ms-scroll-snap-type: none;
            scroll-snap-type: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    scroll-behavior: auto;
  }

  .a11y-slider-prev,
  .a11y-slider-next {
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    background: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 3px;
    font-size: 0;
    width: 3rem;
    height: 3rem;
    padding: 0.2rem 0.5rem;
    background:none;
    transition:background .3s ease-in-out, border-color .3s ease-in-out;

    &:after {
      content: "\2794";
      display: inline-block;
      font-size: 1.5rem;
      color:$color-base;
      transition:color .3s ease-in-out;
    }

    &:hover,
    &:focus-visible {
      border-color:$color-navy;
      background:$color-navy;

      &:after {
        color:$color-white;
      }
    }
  }

  .a11y-slider-prev {
    left:0;

    &:after {
      transform:scaleX(-1);
    }
  }

  .a11y-slider-next {
    right:0;
  }

  .a11y-slider-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    position: relative;

    li {
      display: block;
      flex: 1 auto;
      padding: 0;
    }

    button {
      display: block;
      font-size: 0;
      text-indent: -9999px;
      border: none;
      width: 100%;
      height: 5px;
      background:none;
      transition:background .3s ease-in-out;

      &.active {
        background:$color-navy;
      }
    }
  }