.document-list {
	display: flex;
	flex-direction: column;
	
	.document {
		margin-bottom:1rem;
		padding-bottom:1rem;
		border-bottom:1px solid #ccc;
	}
}

.document {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap:.5rem;
}

.document__icon {
	width:30px;
	height:30px;
	fill:$color-primary;
}

.document__toggle {
	@extend %buttonreset;
}

@media(min-width:$bp-sw){
	.document {
		flex-direction: row;

		.document__action {
			margin-left:auto;
		}
	}
}