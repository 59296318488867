.list {
    margin-top: 2rem;
    margin-bottom: 2rem;
  
    &.list-card-numbered {
      .list {
        &__heading {
          margin-bottom: 2rem;
  
          @media (min-width: $bp-mw) {
            margin-bottom: 0;
          }
        }
  
        &__group {
          margin: 0;
          padding: 0;
          list-style: none;
          counter-reset: list-counter;
  
          li:nth-child(4) {
            @media (min-width: $bp-mw) {
              break-before: column;
              border-top: none;
            }
          }
        }
  
        &__item {
          counter-increment: list-counter;
          position: relative;
  
          &:before {
            content: counter(list-counter) ".";
            color: $color-primary;
            background: $color-blue-light-alt;
            font-size: 1.255rem;
            font-family: $font-heading;
            text-align: center;
            font-weight: $fontWeight-bold;
            width: 3rem;
            height: 3rem;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
  
            @media (min-width: $bp-mw) {
              font-size: 2.5rem;
              width: 5rem;
              height: 5rem;
              position: absolute;
              left: 1.5rem;
            }
          }
  
          &:not(:first-child) {
            margin-top: 1.75rem;
            padding-top: 3rem;
            border-top: 1px solid $color-blue-light-washed;
  
            @media (min-width: $bp-mw) {
              margin-top: 0;
              padding-top: 0;
              border-top: none;
              border-left: 1px solid $color-blue-light-washed;
            }
          }
        }
        &__item-inner {
          @media (min-width: $bp-mw) {
            padding-left: 8rem;
          }
        }
  
        &__item-title {
          font-family: $font-primary;
        }
      }
    }
    &.list-card-numbered--two-columns,
    &.list-card-numbered--one-column {
      .list {
        &__heading {
          margin-bottom: 2rem;
  
          @media (min-width: $bp-mw) {
            margin-bottom: 0rem;
          }
        }
  
        &__group {
          padding-left: 0.938rem;
          padding-right: 0.938rem;
        }
  
        &__item {
          display: flex;
          align-items: center;
          min-height: 3rem;
  
          &:before {
            width: 3rem;
            height: 3rem;
            margin-top: 0.125rem;
            margin-bottom: 0;
            flex: 0 1 auto;
            left: 0;
  
            @media (min-width: $bp-mw) {
              width: 5rem;
              height: 5rem;
            }
          }
  
          &:not(:first-child) {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid $color-blue-light-washed;
            border-bottom: none;
            border-left: none;
          }
  
          @media (min-width: $bp-mw) {
            min-height: 6rem;
  
            &:last-child {
              min-height: 5rem;
            }
  
            &:not(:first-child) {
              border-left: none;
            }
  
            &.mid-item {
              break-before: column;
              border-top: none;
            }
          }
        }
  
        &__item-inner {
          width: calc(100% - 4rem);
          margin-left: auto;
          flex: 0 1 auto;
  
          @media (min-width: $bp-mw) {
            width: auto;
            margin-left: 0;
            padding-left: 6rem;
          }
        }
  
        &__item-title {
          font-size: 1rem;
          margin-bottom: 0;
        }
      }
    }
  
    &.list-card-numbered--two-columns {
      .list {
        &__group {
          padding-left: 0.938rem;
          padding-right: 0.938rem;
  
          @media (min-width: $bp-mw) {
            column-count: 2;
            column-gap: 1rem;
          }
        }
      }
    }
}