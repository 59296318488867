@use '../../abstracts/' as *;

.leadership {
    border-top:1px solid #ddd;
    padding:1rem 0;

    &:first-child {
        border-top:0;
    }
}

.leadership__media {
    max-width:150px;
}

.leadership__name {
    margin:0 0 10px;
    font-size:1.5625rem;
}

.leadership__position {
    margin:10px 0 0;
    font-style:italic;
}

.leadership__links {
    display: flex;
    align-items: center;
    margin:10px 0 0 ;
    padding:0;
    list-style-type: none;

    li + li {
        border-left:1px solid #ddd;
        margin-left:5px;
        padding-left:5px;
    }
}

.leadership__phone {
    display: inline-block;
    margin:10px 0 0;
}

.leadership-list {
    margin:0;
    padding:0;
    list-style-type: none;
}

.leadership-list.leadership-list--alt {
    display: flex;
    flex-wrap:wrap;
    gap:1rem;
    justify-content: center;

    .leadership {
        width:50%;
        border-top:0;
        margin-bottom:1rem;
    }

    .leadership__media + .leadership__body {
        margin:10px 0 0;
        padding-left:0;
    }
}

@media(min-width:$bp-m){
    .leadership-list.leadership-list--alt {
        justify-content: flex-start;

        .leadership {
            width:25%;
        }
    }
}