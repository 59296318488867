.global-footer {
  background-color: $color-white;

  a {
    text-decoration: none;

    &:hover, &:focus-visible {
      text-decoration: underline;
    }
  }

  ul:not(.list-inline) {
    @extend %listreset;
  }

  & > .container-fluid,
  & > .container-fluid > .container {
    display: flex;
    flex-direction: column;
    gap:32px;
  }
}

.footer-to-top {
  width: 100%;
  background: #F6F5F1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0px 11px;
  border-top: 1px solid #CCD4DC;

  .back-to-top {
    color: $color-navy;
    cursor: pointer;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    
    span {
      margin-left: 5px;
      color: $color-navy;
    }
  }
}

.footer-social {
  font-size: 1.2em;
}

.footer-title {
  color: $color-navy;
  font-size:1.25rem;
}

.footer-col-layout {
  display: flex;
  flex-direction: column;
  gap:30px;
}

.footer-col-layout.footer-col-layout--alt {
  align-items: center;
}

.footer-col-layout:not(.footer-col-layout--alt) {
  ul {
    display:block;
    columns:2;
    column-gap:clamp(8px, 1.5vw, 30px);
  }
}

.footer-col {
  display: flex;
  flex-direction: column;
  row-gap:1rem;

  &:empty {
    display: none;
  }
}

.footer-content {
  border-top:1px solid $color-lightgray2;
  border-bottom:1px solid $color-lightgray2;
  padding:1rem 0;
  font-size:.875rem;
  color:$color-navy;
}

.footer-copy {
  font-size:0.75rem;

  .sf-content-block {
    display: inline;
  }

  a {
    text-decoration: none;
    color:#337ab7;
  }
}

@media(min-width:$bp-m){
  .footer-col-layout {
    display: flex;
    flex-direction: row;

    .footer-col {
      flex-grow:1;
    }
  }

  .footer-col-layout:not(.footer-col-layout--alt) {
    flex-wrap:wrap;
    column-gap: 0;

    .footer-col {
      justify-content: flex-start;
      width:50%;
    }
  }

  .footer-col {

    &.footer-col--locations {
      ul {
        columns:5;
      }
    }
  }
}