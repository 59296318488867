.header__search {
    display: flex;
}

.site-search {
    border: 2px solid rgba(0,39,78,.1);
    border-radius: 4px;
    transition:border-color .3s ease-in-out;
    background:$color-white;

    .header-bottom__col & {
        position:absolute;
        top:100%;
        left:0;
        width:100%;
        opacity:0;
        transition:opacity .3s ease-in-out;

        &.show {
            opacity: 1;
        }
    }
}

.search-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border:0;
    border: 2px solid rgba(0,39,78,.1);
    border-radius: 4px;
    padding: 10px 0;
    background:none;
    font-weight: $fontWeight-bold;
    color: $color-navy;
    z-index: 2;
    transition:border-color .3s ease-in-out, background .3s ease-in-out, color .3s ease-in-out;

    &:hover,
    &:focus-visible {
        border-color:$color-navy;
        background:$color-navy;
        color:$color-white;
    }

    .fa-close {
        display: none;
    }

    &[aria-expanded="true"] {
        border-color:transparent;
        .fa-search {
            display: none;
        }

        .fa-close {
            display: block;
        }
    }
}

.site-search__controls {
    width:100%;

    .form-control {
        border:0;
        border-radius: 4px;
    }
}

.site-search__submit {
    border:0;
    background:none;
    color:$color-navy;

    & .site-search__controls {
        position: absolute;

        height:100%;
    }
}

@media(min-width:$bp-l){
    .site-search {
        .header-bottom__col & {
            top:0;
            right:0;
            left:auto;
            width: 0;
            height: 100%;
            opacity:1;
            transition: width .3s ease-in-out;

            &.show {
                width:100%;
            }
        }
    }
}

.sf-autocomplete {
    width:460px !important;
    max-width:100%;
    margin:0; padding:0;
    list-style-type: none;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.075);

    li:first-child a { border-top-left-radius: 4px; border-top-right-radius: 4px; }
    li:last-child a { border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
    
    a {
        position:relative;
        display: block;
        padding:10px 15px;
        border:1px solid #ddd;
        border-top:0;
        background:#fff;
        color:#555;
        cursor:pointer;
        transition:all 0.3s ease-in-out;
        &:hover, &:focus {
            background:#f5f5f5;
            text-decoration: none;
            color:#555;
        }
    }
}