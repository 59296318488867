.header-utility {
  padding: 8px 15px;
  background: $color-sand;

  .sf-content-block {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: $fontWeight-bold;
    line-height: 1.1;
  }

  .btn {
    padding: 10px 16px;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.header-bottom {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border-bottom: 1px solid #d8d8d8;
  border-top: none;
  margin-bottom: 0;
  position: relative;

  .header-bottom__col:first-child {
    margin-right:auto;
  }

  .header-bottom__col:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

@media(min-width:$bp-l){
  .header-bottom__col:nth-child(2) {
    position:relative;
  }
}