.list {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  ol,
  ul {
    &.list-content {
      ul,
      ol {
        @include x-rem(padding-bottom, 5px);
  
        li {
          @include x-rem(margin-top, 10px);
          margin-bottom: 0;
        }
      }
  
      li {
        margin-bottom: 0.8rem;
      }
    }
  }